<template>
  <collapse-card :model-value="false">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Style Guide Details
      </span>
    </template>
    <template #cardContent>
      <div v-if="styleGuideOptionsLoading" class="flex justify-center p-40">
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>
      <div v-else class="py-2 px-4">
        <div>
          <div class="grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
            <base-input
              v-model="dealSummaryData.styleGuideDetails.searchKeyword"
              type="text"
              container-class="flex items-center"
              label="Search"
              label-class="label-w-46"
            />
            <div class="flex">
              <base-button
                variant="btn-primary"
                :disabled="!dealSummaryData.styleGuideDetails.searchKeyword.length"
                class="mr-3"
                text="Search"
                @click.stop.prevent="fetchStyleGuideDetails(1)"
              />
              <base-button
                variant="btn-link"
                text="Cancel"
                @click="resetSearch()"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-end mb-4">
          <base-pagination
            :model-value="dealSummaryData.styleGuideDetails.tableData.page"
            :total-pages="dealSummaryData.styleGuideDetails.tableData.pageCount"
            @update:modelValue="handlePageUpdate($event)"
          />
        </div>
        <brand-assurance-table
          v-model="dealSummaryData.styleGuideDetails.tableData"
          root-element-class="mb-10"
          :show-check-box-column="false"
        >
          <!-- inject custom content in each cell of style guide column -->
          <template
            v-for="(styleGuideDetail, sIndex) in dealSummaryData.styleGuideDetails.tableData.data"
            :key="'cell-slot' + sIndex"
            #[generateTableSlotName(sIndex,0)]
          >
            <div
              class="cursor-pointer"
              :class="{
                'font-semibold': dealSummaryData.characterDetails.selectedStyleGuide === styleGuideDetail.styleGuide
              }"
              @click="selectStyleGuide(styleGuideDetail.styleGuide)"
            >
              {{ styleGuideDetail.styleGuide }}
            </div>
          </template>
        </brand-assurance-table>
      </div>
    </template>
  </collapse-card>
</template>

<script>
import useDealSummary from './dealSummary.js';
import useBaTable from '@/hooks/baTable.js';
import { defineAsyncComponent, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { formatDate } from '@/helpers/util';

export default {
    name: 'StyleGuideDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue'))
    },

    setup () {
        const store = useStore();
        const { dealSummaryData } = useDealSummary();
        const { generateTableSlotName } = useBaTable();

        const resetSearch = () => {
            dealSummaryData.styleGuideDetails.searchKeyword = '';
            dealSummaryData.styleGuideDetails.tableData.page = 1;
            fetchStyleGuideDetails();
        };
        const selectStyleGuide = (newStyleGuide) => {
            dealSummaryData.characterDetails.selectedStyleGuide = newStyleGuide;
        };
        const handlePageUpdate = (newPage) => {
            dealSummaryData.styleGuideDetails.tableData.page = newPage;
            fetchStyleGuideDetails();
        };

        onMounted(async () => {
            await fetchStyleGuideDetails();
        });

        const fetchedStyleGuideeDetails = ref([]);
        const styleGuideOptionsLoading = ref(false);
        const fetchStyleGuideDetails = async (pageNum) => {
            try {
                styleGuideOptionsLoading.value = true;
                if (pageNum) dealSummaryData.styleGuideDetails.tableData.page = 1;
                fetchedStyleGuideeDetails.value = await store.dispatch('baStyleGuides/fetchDrmStyleGuideContractList', {
                    params: {
                        searchKeyword: dealSummaryData.styleGuideDetails.searchKeyword,
                        page: dealSummaryData.styleGuideDetails.tableData.page,
                        limit: dealSummaryData.styleGuideDetails.tableData.limit,
                        contractNumber: dealSummaryData.contractNumber
                    },
                    onlyReturnValues: true
                });
                dealSummaryData.styleGuideDetails.tableData.data = fetchedStyleGuideeDetails.value.data.map(obj => {
                    obj.startDate = formatDate(obj.startDate);
                    obj.endDate = formatDate(obj.endDate);
                    return obj;
                });
                dealSummaryData.styleGuideDetails.tableData.pageCount = fetchedStyleGuideeDetails.value.pageCount;
                dealSummaryData.styleGuideDetails.tableData.totalCount = fetchedStyleGuideeDetails.value.totalCount;
            } catch (err) {
                console.error(err);
            } finally {
                styleGuideOptionsLoading.value = false;
            }
        };

        return {
            dealSummaryData,
            generateTableSlotName,
            resetSearch,
            selectStyleGuide,
            fetchStyleGuideDetails,
            handlePageUpdate,
            styleGuideOptionsLoading
        };
    }
};
</script>
